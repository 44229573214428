<template>
  <div class="section grid grid-cols-3 gap-5">
    <a
      v-for="social in socials"
      :key="social.name"
      :href="social.href"
      class="w-fit"
    >
      <BaseSvgNew :name="social.icon" class="w-10 h-10" />
    </a>
  </div>
</template>

<script setup lang="ts">
const socials = [
  {
    name: 'vkontakte',
    icon: 'vkontakte-white',
    href: 'https://vk.com/pony.market',
  },
  {
    name: 'telegram',
    icon: 'telegram-white',
    href: 'https://t.me/ponymarket_russia',
  },
  {
    name: 'whatsapp',
    icon: 'whatsapp-white',
    href: 'https://wa.me/79151477707',
  },
  // {
  //   id: 3,
  //   img: yt,
  //   href: 'https://www.youtube.com/channel/UCQeWk7mg9GT7zPu1AJWOkMw',
  // },
];
</script>

<style scoped></style>
