<template>
  <div class="flex flex-col">
    <NuxtLink
      v-for="info in information"
      :key="info.id"
      class="footer-content"
      :to="info.href"
    >
      {{ info.title }}
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
const information = [
  { title: 'Популярные магазины', href: '/shops' },
  { title: 'Способы оплаты', href: '/payment-methods' },
  { title: 'Лимиты', href: '/limits' },
  { title: 'Ограничения по услуге', href: '/limitations' },
  { title: 'Условия доставки', href: '/shipping-calculator' },
];
</script>

<style scoped>
.footer-content {
  @apply mb-2 text-aluminum font-normal text-base leading-6 hover:text-white transition-colors;
}
</style>
