<template>
  <div class="flex flex-1 items-center lg:h-5">
    <ul class="flex flex-col lg:flex-row flex-1 text-base leading-5 font-bold">
      <li
        v-for="item in navigation"
        :key="item.name"
        class="mb-5 lg:mb-0 last:mb-0 lg:mr-7 last:mr-0"
      >
        <component
          :is="item.href.startsWith('http') ? 'a' : NuxtLink"
          v-if="item.href"
          class="text-[#2E2E2E] whitespace-nowrap transition-colors hover:text-green-basic"
          :href="item.href.startsWith('http') ? item.href : undefined"
          :to="!item.href.startsWith('http') ? item.href : undefined"
          @click="onRedirectHandler"
        >
          {{ item.title }}
        </component>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { NuxtLink } from '#components';

type NavItem = {
  name: string;
  href: string;
  title: string;
};

type Props = {
  navigation: NavItem[];
};

defineProps<Props>();

const emit = defineEmits<{
  (e: 'redirect'): void;
}>();

const onRedirectHandler = () => {
  emit('redirect');
};
</script>
