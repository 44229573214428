<template>
  <div class="flex flex-col">
    <NuxtLink
      v-for="company in companies"
      :key="company.id"
      class="footer-content"
      :to="company.href"
    >
      {{ company.title }}
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
const companies = [
  { title: 'Контакты', href: '/contacts' },
  { title: 'Пользовательское соглашение', href: '/terms' },
  { title: 'Политика конфиденциальности', href: '/terms/privacy' },
];
</script>

<style scoped>
.footer-content {
  @apply mb-2 text-aluminum font-normal text-base leading-6 hover:text-white transition-colors;
}
</style>
