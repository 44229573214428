<template>
  <button
    class="burger-btn"
    :class="{ active: isShown }"
    @click="onClickHandler"
  >
    <div v-for="i in 3" :key="i" />
  </button>
</template>

<script setup lang="ts">
defineProps<{
  isShown: boolean;
}>();

const emit = defineEmits<{
  (e: 'click'): void;
}>();

const onClickHandler = () => {
  emit('click');
};
</script>

<style scoped lang="scss">
.burger-btn {
  @apply w-10 h-10 flex flex-col justify-center items-center border-2 border-solid border-green-basic rounded-full;

  > div {
    @apply w-5 h-0.5;
    @apply mb-1 last:mb-0;
    @apply border border-solid;
    @apply bg-green-basic border-green-basic;
    @apply transition-all;
  }
  &.active {
    div {
      &:nth-child(1) {
        @apply -mb-0.5;
        transform: rotate(45deg);
      }
      &:nth-child(2) {
        @apply hidden;
      }
      &:nth-child(3) {
        transform: rotate(-45deg);
      }
    }
  }
}
</style>
