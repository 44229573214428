<template>
  <div ref="wrapperRef" class="popup">
    <button class="close-btn" @click="closeModal">
      <BaseSvgNew name="close" />
    </button>
    <div v-if="title || slots.title" class="title">
      <slot name="title">{{ title }}</slot>
    </div>
    <slot />
  </div>
</template>

<script setup lang="ts">
type Props = {
  title?: string;
};

withDefaults(defineProps<Props>(), {
  title: '',
});

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const slots = useSlots();

const wrapperRef = ref<HTMLDivElement>();

onClickOutside(wrapperRef, () => closeModal());

const closeModal = () => {
  emit('close');
};
</script>

<style scoped lang="scss">
.popup {
  @apply relative rounded-[20px] m-auto;
  @apply p-7;
  @apply bg-white;
  .title {
    @apply mt-0 mb-5;
    @apply text-2xl leading-[30px] font-bold;
  }
  .close-btn {
    @apply absolute right-4 top-2 z-10;
    @apply h-6 w-6;
    @apply text-[#9e9e9e] hover:text-black transition-colors;
    @screen md {
      @apply block;
    }
  }
}
</style>
