<template>
  <div class="flex flex-col flex-1">
    <ul class="flex flex-col flex-1 lg:flex-row text-base leading-5 font-bold">
      <li
        v-for="nav in navigation"
        :key="nav.id"
        class="mb-2 last:mb-0 p-4 rounded-2xl bg-white"
      >
        <component
          :is="nav.href.startsWith('http') ? 'a' : NuxtLink"
          v-if="nav.href"
          class="text-green-basic whitespace-nowrap transition-opacity active:opacity-70"
          :href="nav.href.startsWith('http') ? nav.href : undefined"
          :to="!nav.href.startsWith('http') ? nav.href : undefined"
          @click="onRedirectHandler"
        >
          {{ nav.title }}
        </component>
      </li>
    </ul>
    <div class="flex items-center justify-center mt-6">
      <a href="https://t.me/ponymarket_russia" class="mr-5">
        <BaseSvgNew name="footer-media-tg" class="w-10 h-10 text-green-basic" />
      </a>
      <a href="https://vk.com/pony.market" class="mr-5">
        <BaseSvgNew name="footer-media-vk" class="w-10 h-10 text-green-basic" />
      </a>
      <a href="https://www.youtube.com/channel/UCQeWk7mg9GT7zPu1AJWOkMw">
        <BaseSvgNew name="footer-media-yt" class="w-10 h-10 text-green-basic" />
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import { NuxtLink } from '#components';

type Props = {
  navigation: {
    id: number;
    href: string;
    title: string;
  }[];
};

defineProps<Props>();

const emit = defineEmits<{
  (e: 'redirect'): void;
}>();

const onRedirectHandler = () => {
  emit('redirect');
};
</script>
