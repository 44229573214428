<template>
  <div class="flex flex-col">
    <div v-for="item in useful" :key="item.id" class="footer-content">
      <component
        :is="item.href.startsWith('http') ? 'a' : NuxtLink"
        :href="item.href.startsWith('http') ? item.href : undefined"
        :to="!item.href.startsWith('http') ? item.href : undefined"
      >
        <p>{{ item.title }}</p>
      </component>
    </div>
  </div>
</template>

<script setup lang="ts">
import { NuxtLink } from '#components';

const useful = [
  { id: 1, title: 'Доставка', href: '/#delivery' },
  // {
  //   id: 2,
  //   title: 'Отслеживание',
  //   href: 'https://www.ponyexpress.ru/support/servisy-samoobsluzhivaniya/track/',
  // },
  // TODO Не рабочая ссылка
  // {
  //   id: 3,
  //   title: 'Таблица размеров',
  //   href: 'https://blog.pony.market/index.php/2020/07/08/size/',
  // },
  { id: 4, title: 'Калькулятор', href: '/shipping-calculator' },
];
</script>

<style scoped>
.footer-content {
  @apply mb-2 text-aluminum font-normal text-base leading-6 hover:text-white transition-colors;
}
</style>
